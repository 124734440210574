<!-- for pushing -->

<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-10">
      <div class="title text-center">
        <h1>Elmar Beckmann</h1>
        <h4>Frontend Developer</h4>
        <br />

        <ul class="list-inline icons">
          <li class="list-inline-item">
            <i class="devicon-javascript-plain colored"></i>
          </li>
          <li class="list-inline-item">
            <i class="devicon-html5-plain colored"></i>
          </li>
          <li class="list-inline-item">
            <i class="devicon-angularjs-plain colored"></i>
          </li>
          <li class="list-inline-item">
            <i class="devicon-bootstrap-plain colored"></i>
          </li>
          <li class="list-inline-item">
            <i class="devicon-css3-plain colored"></i>
          </li>
          <li class="list-inline-item">
            <i class="devicon-drupal-plain colored"></i>
          </li>
          <li class="list-inline-item">
            <i class="devicon-foundation-plain colored"></i>
          </li>
          <li class="list-inline-item">
            <i class="devicon-git-plain colored"></i>
          </li>
          <li class="list-inline-item">
            <i class="devicon-nginx-plain colored"></i>
          </li>
          <li class="list-inline-item">
            <i class="devicon-php-plain colored"></i>
          </li>
          <li class="list-inline-item">
            <i class="devicon-sass-plain colored"></i>
          </li>
          <li class="list-inline-item">
            <i class="devicon-typescript-plain colored"></i>
          </li>
          <li class="list-inline-item">
            <i class="devicon-visualstudio-plain colored"></i>
          </li>
          <li class="list-inline-item">
            <i class="devicon-docker-plain colored"></i>
          </li>
          <li class="list-inline-item">
            <i class="devicon-npm-original-wordmark colored"></i>
          </li>
          <li class="list-inline-item">
            <i class="devicon-bitbucket-original colored"></i>
          </li>
        </ul>
      </div>

      <br /><br />
      <!-- <hr /> -->

      <h4>Summary</h4>
      <div class="summary">
        <p>
          Working for
          <a href="https://www.openweb.nl" target="_blank" title="Open Web"
            >Open Web IT</a
          >
          as a Senior Frontend Developer / consultant, hosting a
          <a target="_blank" href="https://koenenelmarshow.nl/">podcast</a>
          together with
          <a href="https://twitter.com/KoenBrouwer" target="_blank"
            >@KoenBrouwer</a
          >
          and I am coding live on
          <a href="https://www.twitch.tv/elmardotdev" target="_blank"
            >my Twitch channel</a
          >
          regularly.
        </p>
        <p>
          In the past I've been doing freelance (web)designer work. I worked
          nationally and internationally with a variety of commercial clients
          and have continued to produce personal work, completing some major
          projects.
        </p>
      </div>

      <br />

      <h4>Specialties</h4>

      <div class="specialties">
        <div class="row">
          <div class="col-md-4 col-sm-4">
            <p>Angular 11+</p>
            <p>AngularJS</p>
            <p>Javascript (ES6)</p>
            <p>Typescript</p>
            <p>RxJS</p>
            <p>REST Api's</p>
          </div>
          <div class="col-md-4 col-sm-4">
            <p>SPA/PWA</p>
            <p>Jasmine / Karma / Jest (Unit testing)</p>
            <p>Cypress (E2E testing)</p>
            <p>Capacitor (Ionic)</p>
            <p>Bootstrap/Angular Material</p>
            <p>Docker</p>
          </div>
          <div class="col-md-4 col-sm-4">
            <p>Coaching / Lead</p>
            <p>Creative input</p>
            <p>Agile / Scrum</p>
            <p>Final Cut Pro (7/X)</p>
            <p>UI/UX</p>
            <p>+ much more</p>
          </div>
        </div>
      </div>

      <br />

      <h4>Contact</h4>
      <div class="contact">
        <p>
          Mail me at <a href="mailto:me@elmar.dev">me@elmar.dev</a>, look me up
          on
          <a href="https://www.linkedin.com/in/elmarbeckmann/">LinkedIn</a> or
          reach out on
          <a href="https://twitter.com/elmardotdev">Twitter.</a>
        </p>
      </div>

      <br /><br />
    </div>
  </div>
</div>
